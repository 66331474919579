import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import i18n from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { appURLs } from "@/app/constants.mjs";
import { formatDuration } from "@/app/util.mjs";
import MatchTabs from "@/game-lol/components/MatchTabs.jsx";
import Badges from "@/game-lol/components/PostMatchBadges.jsx";
import {
  STAT_COMPARE,
  STAT_TRENDS,
} from "@/game-lol/constants/coaching-constants.mjs";
import RanksColors from "@/game-lol/constants/colors.mjs";
import {
  EVENT_LOL_COACHING_POSTMATCH,
  LANE_PHASE_END,
  MAX_GAME_TIME_IN_MINUTES,
  MID_PHASE_END,
  QUEUE_SYMBOLS,
  RANK_SYMBOL_TO_STR,
  RANK_SYMBOLS,
  REGION_LIST,
} from "@/game-lol/constants/constants.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import {
  abilitiesPerformance,
  coachableQueue,
  coachingPerformance,
} from "@/game-lol/utils/coaching-utils.mjs";
import getHextechRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
import isLinkedSummoner from "@/game-lol/utils/is-linked-summoner.mjs";
import { findPlayer, playerWon } from "@/game-lol/utils/match-utils.mjs";
import Static from "@/game-lol/utils/static.mjs";
import {
  coachingStatsKey,
  coachingStatsVariables,
  getDerivedId,
  getDerivedRiotID,
  getStaticData,
  getTranslatedQueueName,
} from "@/game-lol/utils/util.mjs";
import combatIcon from "@/inline-assets/coaching-combat.svg";
import econIcon from "@/inline-assets/coaching-econ.svg";
import visionIcon from "@/inline-assets/coaching-vision.svg";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import {
  MatchPageContainer,
  MatchStatContainer,
  RankComparison,
  Subtitle,
} from "@/shared/Match.style.jsx";
import MatchGraph from "@/shared/MatchGraph.jsx";
import { PerfGrid } from "@/shared/Performance.style";
import PerformanceCard, {
  CharacterStat,
  PerformanceCardLoading,
} from "@/shared/PerformanceCard.jsx";
import SimpleLine from "@/shared/SimpleLineChart.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { MatchContextProvider } from "@/shared/use-match-context.mjs";
import { devError } from "@/util/dev.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

// The LCU is missing a few spell keys (3 & 4) and thus we need to check
// if we should render the champ performance. In the future we should get
// this data from core/reversing.
// NOTE: "SPELL" means "ABILITY" in this context, aka Q/W/E/R
function hasAllSpells(participant = {}) {
  const keys = ["spell1Casts", "spell2Casts", "spell3Casts", "spell4Casts"];

  let keysFound = 0;
  for (const key of keys) {
    const val = participant[key];
    if (typeof val === "number") keysFound += 1;
  }

  return keysFound === keys.length;
}

const chartConfig = {
  margin: { left: 0, right: 60, top: 40, bottom: 40 },
  height: 340,
};

function PostMatch() {
  // useReRender();
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const route = useRoute();
  const {
    parameters: [region, name, matchId, tab, tabId],
  } = route;

  // Read fetched match data from state
  // 1) The match data itself
  // 2) The corresponding match timeline
  // 3) (*optional*) Arena tabs
  // 4) By Minute division stats

  const derivedId = getDerivedId(region, name);
  const account = state.lol?.profiles?.[derivedId];

  const riotAccount = account?.riotAccount;
  if (riotAccount) {
    const { gameName, tagLine } = account.riotAccount;
    try {
      const derivedRiotID = getDerivedRiotID(gameName, tagLine);
      if (derivedRiotID !== name) {
        updateRoute(`/lol/match/${region}/${derivedRiotID}/${matchId}`);
      }
    } catch (e) {
      devError(
        "Failed to derive Riot ID from riotAccount",
        e,
        profile.riotAccount,
      );
    }
  }

  const profile = state.lol.profiles?.[derivedId];

  const isOwnProfile = isLinkedSummoner(state, region, name);
  const match = state.lol?.matches?.[`${region?.toUpperCase()}_${matchId}`];
  const matchMins = match?.gameDuration / 60 || 1;
  const timeline = state.lol?.matchTimeline?.[matchId];

  const matchContext = useMemo(
    () => ({ tab, tabId, isOwnProfile, match }),
    [isOwnProfile, match, tab, tabId],
  );

  const champions = getStaticData("champions");

  const benchmarkTier =
    (account?.latestRanks || []).find((queue) => {
      return queue.queue === QUEUE_SYMBOLS.rankedSoloDuo;
    })?.tier || RANK_SYMBOLS.gold;

  const currentParticipant = findPlayer(
    { ...account, summonerName: name },
    match,
  );

  // Graft the account puuid onto the player because LCU eog-stats do not have one
  // we need the puuid to find the player in the timeline
  const player = account?.puuid
    ? { ...currentParticipant, puuid: account.puuid, rank: benchmarkTier }
    : { ...currentParticipant, rank: benchmarkTier };

  const championKey = champions?.keys?.[player.championId];
  const champion = champions?.[championKey];

  const coachable = coachableQueue(match);

  const showBadges = true;

  const playerRank = player.rank || RANK_SYMBOLS.gold;
  const RankIcon = getHextechRankIcon(RANK_SYMBOL_TO_STR[playerRank].gql);
  const rankText = t(
    RANK_SYMBOL_TO_STR[playerRank].t.name,
    RANK_SYMBOL_TO_STR[playerRank].t.fallback,
  );

  const characterPerfEnabled = coachable && hasAllSpells(currentParticipant);

  const MatchLayout = lolRefs.PostMatchLayout ?? SharedMatchLayout;
  return (
    <MatchContextProvider value={matchContext}>
      <MatchLayout
        match={match}
        title={
          !match
            ? t("common:loading", "Loading...")
            : playerWon(currentParticipant, match)
              ? t("lol:postmatch.victory", "Victory")
              : t("lol:postmatch.defeat", "Defeat")
        }
        image={Static.getChampionImage(currentParticipant?.championId)}
        borderColor={
          !match
            ? "var(--shade3)"
            : playerWon(currentParticipant, match)
              ? "var(--turq)"
              : "var(--red)"
        }
        imageLink={`/lol/profile/${region}/${name}`}
        underTitle={
          <Subtitle className="type-body2">
            <span>{getTranslatedQueueName(t, match?.queueId)}</span>
            <span>
              {match
                ? formatDuration(
                    match.gameDuration / 60 > MAX_GAME_TIME_IN_MINUTES
                      ? match.gameDuration
                      : match.gameDuration * 1000,
                    "mm:ss",
                  )
                : "—"}
            </span>
            <TimeAgo date={match ? match?.gameCreation : "—"} />
          </Subtitle>
        }
      >
        <MatchPageContainer>
          {showBadges ? (
            <Badges region={region} name={name} matchId={matchId} />
          ) : null}
          {coachable && (
            <RankComparison>
              <span className="vs type-form--button">
                {t("common:vs", "vs")}
              </span>
              {RankIcon && <RankIcon />}
              <span className="type-form--button">{rankText}</span>
            </RankComparison>
          )}
          {characterPerfEnabled ? (
            <CharacterPerformance
              player={player}
              matchMins={matchMins}
              champion={champion}
            />
          ) : null}
          {coachable ? (
            <MatchPerformance
              match={match}
              timeline={timeline}
              player={{
                ...player,
                rank: benchmarkTier,
              }}
              champion={champion}
            />
          ) : null}
          <MatchTabs region={region} matchId={matchId} profile={profile} />
        </MatchPageContainer>
      </MatchLayout>
    </MatchContextProvider>
  );
}

function CharacterPerformance({ player, matchMins, champion }) {
  const state = useSnapshot(readState);
  const playerRank = player.rank || RANK_SYMBOLS.gold;

  const championAbilities = champion?.spells?.filter(
    (ability) => ability.image.group !== "passive",
  );

  if (!championAbilities) return null;

  const byMinVariables = coachingStatsVariables({
    tier: playerRank,
    role: player?.teamPosition,
    championId: player?.championId,
  });

  const divisionData =
    state.lol?.championStatsByMin?.[coachingStatsKey(byMinVariables)]
      ?.divisionStats;

  const isDivisionLoading = typeof divisionData === "undefined";
  const isLoading = isDivisionLoading;

  if (isLoading) {
    return (
      <MatchStatContainer>
        <PerfGrid>
          <PerformanceCardLoading height="11.875rem" />
          <PerformanceCardLoading height="11.875rem" />
          <PerformanceCardLoading height="11.875rem" />
          <PerformanceCardLoading height="11.875rem" />
        </PerfGrid>
      </MatchStatContainer>
    );
  }

  const abilitiesPerf = abilitiesPerformance({
    player,
    champion,
    matchMins,
    divisionData,
  });

  const perf = championAbilities.map((ability, i) => {
    const stat = abilitiesPerf[i][0];

    return {
      key: ability.id,
      image: Static.getChampionSpellImageById(ability.id),
      score: stat.score,
      label: ability.name,
      disabled: !stat.value,
      disabledText: [
        "lol:coaching.passivesNotSupported",
        "Passive abilities are not currently supported.",
      ],
      // stats: abilitiesPerf[i].map((stat) => stat),
      stats: [],
    };
  });

  return (
    <MatchStatContainer>
      <PerfGrid>
        {perf.map((ability) => {
          return <CharacterStat key={ability.id} {...ability} />;
        })}
      </PerfGrid>
    </MatchStatContainer>
  );
}

function MatchPerformance({ match, timeline, player }) {
  const state = useSnapshot(readState);
  const playerRank = player.rank || RANK_SYMBOLS.gold;

  // Division Stats aka stats BY MINUTE (aggregate timeline)
  const byMinVariables = coachingStatsVariables({
    tier: playerRank,
    role: player?.teamPosition,
    championId: player?.championId,
  });
  const divisionData =
    state.lol?.championStatsByMin?.[coachingStatsKey(byMinVariables)]
      ?.divisionStats;

  const coachingScores = coachingPerformance({
    player,
    match,
    timeline,
    divisionData,
  });

  const combatBreakdown = useCallback(() => {
    return (
      <Breakdown
        id="combat"
        subStats={coachingScores.combat.stats}
        comparison={playerRank}
      />
    );
  }, [coachingScores, playerRank]);

  const econBreakdown = useCallback(() => {
    return (
      <Breakdown
        id="econ"
        subStats={coachingScores.economy.stats}
        comparison={playerRank}
      />
    );
  }, [coachingScores, playerRank]);

  const visionBreakdown = useCallback(() => {
    return (
      <Breakdown
        id="vision"
        subStats={coachingScores.vision.stats}
        comparison={playerRank}
      />
    );
  }, [coachingScores, playerRank]);

  const combatPerf = coachingScores.combat.perf;
  const economyPerf = coachingScores.economy.perf;
  const visionPerf = coachingScores.vision.perf;

  const combatLabel = ["lol:coaching.combat", "Combat"];
  const economyLabel = ["lol:coaching.economy", "Economy"];
  const visionLabel = ["lol:coaching.vision", "Vision"];

  const tabs = [
    {
      label: combatLabel,
      score: combatPerf.score,
      content: combatBreakdown,
      clickEvent: () => {
        eventBus.emit(EVENT_LOL_COACHING_POSTMATCH, combatLabel[0]);
      },
    },
    {
      label: economyLabel,
      score: economyPerf.score,
      content: econBreakdown,
      clickEvent: () => {
        eventBus.emit(EVENT_LOL_COACHING_POSTMATCH, economyLabel[0]);
      },
    },
    {
      label: visionLabel,
      score: visionPerf.score,
      content: visionBreakdown,
      clickEvent: () => {
        eventBus.emit(EVENT_LOL_COACHING_POSTMATCH, visionLabel[0]);
      },
    },
  ];

  const isDivisionLoading = typeof divisionData === "undefined";
  const isTimelineLoading = typeof timeline === "undefined";

  const isLoading = isTimelineLoading || isDivisionLoading;

  if (isLoading) {
    return (
      <>
        <div style={{ display: "grid", gap: "var(--sp-2)" }}>
          <MatchStatContainer>
            <PerfGrid>
              <PerformanceCardLoading height="23.5rem" />
              <PerformanceCardLoading height="23.5rem" />
              <PerformanceCardLoading height="23.5rem" />
            </PerfGrid>
          </MatchStatContainer>
        </div>
        <PerformanceCardLoading height="34.5rem" />
      </>
    );
  }

  return (
    <>
      <MatchStatContainer>
        <PerfGrid>
          <PerformanceCard
            title="Title"
            icon={combatIcon}
            score={combatPerf.score}
            stats={[]}
          />
          <PerformanceCard
            title="Title"
            icon={econIcon}
            score={economyPerf.score}
            stats={[]}
          />
          <PerformanceCard
            title="Title"
            icon={visionIcon}
            score={visionPerf.score}
            stats={[]}
          />
        </PerfGrid>
      </MatchStatContainer>
      <MatchGraph tabs={tabs} />
    </>
  );
}

function sectionPerf(val1, val2, direction, prefix, renderLabel) {
  const percent =
    direction === STAT_TRENDS.upward ? val1 / val2 : 1 + val1 / val2;

  switch (true) {
    case percent <= STAT_COMPARE.low:
      return {
        color: "var(--primary)",
        text: renderLabel && i18n.t("common:coaching.low", "Low"),
        textPrefix: prefix ? i18n.t(...prefix) : null,
      };
    case percent <= STAT_COMPARE.okay:
      return {
        color: "var(--perf-neutral)",
        text: renderLabel && i18n.t("common:coaching.okay", "Okay"),
        textPrefix: prefix ? i18n.t(...prefix) : null,
      };
    case percent <= STAT_COMPARE.good:
      return {
        color: "var(--perf-pos2)",
        text: renderLabel && i18n.t("common:coaching.good", "Good"),
        textPrefix: prefix ? i18n.t(...prefix) : null,
      };
    case percent > STAT_COMPARE.good:
      return {
        color: "var(--perf-pos3)",
        text: renderLabel && i18n.t("common:coaching.great", "Great"),
        textPrefix: prefix ? i18n.t(...prefix) : null,
      };
    default:
      return {
        color: "var(--perf-neutral)",
        text: renderLabel && i18n.t("common:coaching.okay", "Okay"),
        textPrefix: prefix ? i18n.t(...prefix) : null,
      };
  }
}

function Breakdown({ id = "", subStats = [], comparison }) {
  const { t } = useTranslation();
  const minStart = 2; // Start at the 2min mark

  const options = subStats.map((option) => {
    const benchmarkData = option.benchmark
      .filter((_, min) => min >= minStart && min < option.byMinute.length)
      .map((d) => d.val);

    return {
      value: t(...option.label),
      text: option.label,
      description: option.description && t(...option.description),
      format: option.format,
      minVal: option.min,
      data: benchmarkData.length
        ? [option.byMinute.filter((_, min) => min >= minStart), benchmarkData]
        : [option.byMinute],
    };
  });

  const [selected, setSelected] = useState(0);

  const yAxisFormat = options[selected]?.format || {};
  const data = (options[selected]?.data || []).map((arr) => {
    return arr.map((val, min) => ({
      x: min + minStart,
      y: val,
    }));
  });

  const matchPts = options[selected]?.data?.[0] || [];
  const benchMarkPts = options[selected]?.data[1] || [];
  const direction = STAT_TRENDS.upward;
  const minVal = options[selected]?.minVal;

  const sog = 0; // Start of game
  const eog = matchPts.length - 1; // End of game
  const hasLateGame = eog > MID_PHASE_END - minStart; // Went long enough to have a "late game"
  const lanePhaseEnd = LANE_PHASE_END - minStart; // End of lane phase
  const midPhaseEnd = hasLateGame ? MID_PHASE_END - minStart : eog; // End of mid game

  const sections =
    matchPts.length && benchMarkPts.length && eog >= lanePhaseEnd
      ? [
          {
            start: sog,
            end: lanePhaseEnd,
            ...sectionPerf(
              matchPts[lanePhaseEnd],
              benchMarkPts[lanePhaseEnd],
              direction,
              ["lol:laningPhase", "Laning Phase"],
              true,
            ),
          },
          midPhaseEnd && {
            start: lanePhaseEnd,
            end: midPhaseEnd,
            ...sectionPerf(
              matchPts[midPhaseEnd],
              benchMarkPts[midPhaseEnd],
              direction,
              matchPts.length > lanePhaseEnd + 4 && ["lol:midGame", "Mid Game"],
              matchPts.length > lanePhaseEnd + 4,
            ),
          },
          hasLateGame && {
            start: midPhaseEnd,
            end: eog,
            ...sectionPerf(
              matchPts[eog],
              benchMarkPts[eog],
              direction,
              matchPts.length > midPhaseEnd + 4 && [
                "lol:lateGame",
                "Late Game",
              ],
              matchPts.length > midPhaseEnd + 4,
            ),
          },
        ].filter(Boolean)
      : [];

  const rankColor = RanksColors.ranks[RANK_SYMBOL_TO_STR[comparison].key].fill;

  useEffect(() => {
    setSelected(0);
  }, [id]);

  return (
    <>
      <div className="controls" data-hide-btns={options.length === 1}>
        <ButtonGroup inputStyle block>
          {options.map((option, i) => (
            <Button
              key={`${option.value}_${i}`}
              onClick={() => {
                setSelected(i);
                eventBus.emit(EVENT_LOL_COACHING_POSTMATCH, option.text[0]);
              }}
              data-active={selected === i}
              inputStyle
              block
            >
              {option.value}
            </Button>
          ))}
        </ButtonGroup>
      </div>
      <div className="chart-container">
        {data ? (
          <SimpleLine
            margin={chartConfig.margin}
            data={data}
            labels={[
              { text: ["common:you", "You"], labelColor: "var(--shade0)" },
              {
                text: [
                  RANK_SYMBOL_TO_STR[comparison].t.name,
                  RANK_SYMBOL_TO_STR[comparison].t.fallback,
                ],
                labelColor: rankColor,
              },
            ]}
            xField="x"
            yField="y"
            yAxisPos="right"
            height={chartConfig.height}
            xAxisConf={{
              visible: true,
              ticks: Math.round(data?.[0]?.length),
              everyOther: true,
              tickRenderer: (xValue) => {
                return (xValue + minStart).toFixed(0);
              },
            }}
            yAxisConf={{
              visible: true,
              ticks: 3,
              tickRenderer: (yValue) => {
                return yValue.toLocaleString(getLocale(), yAxisFormat);
              },
            }}
            sections={sections}
            compareFunc={sectionPerf}
            direction={direction}
            minVal={minVal}
            color={[...sections.map((s) => s.color), "var(--shade2)"]}
            fill={[...sections.map((s) => Boolean(s.color)), false]}
            lineWidth={[...sections.map((_) => 3), 2]}
            circleRadius={0}
            showGridLines={true}
          />
        ) : null}
      </div>
      {options[selected]?.description ? (
        <p className="type-caption--semi chart-description">
          {options[selected].description}
        </p>
      ) : null}
    </>
  );
}

export function meta(info) {
  const [region, userName, matchId] = info;
  const encodedName = encodeURIComponent(userName);
  const gqlRegion = REGION_LIST.find((obj) => {
    return obj.key === region;
  })?.gql;

  return {
    title: [
      "lol:meta.postmatch.title",
      "{{userName}}'s League of Legends Match Performance",
      { userName: userName },
    ],
    description: [
      "lol:meta.postmatch.description",
      "View {{userName}}'s League of Legends match history and see how they perform.",
      { userName: userName },
    ],
    image: {
      url: `${appURLs.BLITZ}/lol/match/${gqlRegion}/${encodedName}/${matchId}/preview.png`,
      alt: [
        "lol:meta.postmatch.title",
        "{{userName}}'s League of Legends Match Performance",
        { userName: userName },
      ],
      width: 800,
      height: 400,
    },
  };
}

export default PostMatch;
